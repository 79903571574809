import { IconProps } from "../types";

export const ImgIcon = ({ src, width, height, style }: IconProps) => (
  <img
    src={`/img/icons/${src}`}
    alt={src}
    width={width}
    height={height}
    style={style}
  />
);
