import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { ImgIcon } from "../../common/ImgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  StyledAnchor,
  Chat,
  FooterContainer,
  Language,
  FooterRow,
  Rights,
  RightsContainer,
} from "./styles";

export interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const now = new Date();
  const year = now.getFullYear();
  const nextYear = new Date(
    now.setFullYear(now.getFullYear() + 1)
  ).getFullYear();

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Call Us")}</Language>
              <a href="tel:+919825118564">
                <StyledAnchor>{t(`+91-9825118564`)}</StyledAnchor>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("General Enquiries")}</Language>
              <a href="mailto:tatvamchemicals@gmail.com">
                <StyledAnchor>{t(`tatvamchemicals@gmail.com`)}</StyledAnchor>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Our Timing")}</Language>
              <Para>{t(`Mon - Sun : 09:00 AM - 09:00 PM`)}</Para>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Office Address")}</Language>
              <Para>Patel Dental Clinic</Para>
              <Para>Opp. Shakti Cinema </Para>
              <Para>SBI road, Halvad</Para>
              <Para>Gujarat - 363330.</Para>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:tatvamchemicals@gmail.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <FooterRow justify="space-between" align="middle">
            <NavLink to="/">
              <LogoContainer>
                <ImgIcon
                  src="tatvam_logo_white.png"
                  aria-label="homepage"
                  width="160px"
                  height="auto"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <RightsContainer>
                <Rights>{`© Copyrights ${year} - ${nextYear}.Tatvam Chemicals.`}</Rights>
                <Rights>{`All Rights Reserved.`}</Rights>
              </RightsContainer>{" "}
            </FooterContainer>
          </FooterRow>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
