const routes = [
  {
    path: "/",
    exact: true,
    component: "Home",
  },
  {
    path: "/home",
    exact: true,
    component: "Home",
  },
];

export default routes;
